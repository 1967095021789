.custom-link {
  text-decoration: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 22px;
  line-height: 32px;
  display: inline-block;
  border-radius: 8px;
  padding: 8px 8px 8px 8px;
  margin-right: 64px;
}

.custom-link:link {
  color: #4059AD;
}

.custom-link:visited {
  color: #8F3985;
}

.custom-link:hover {
  background-color: gainsboro;
  color: darkred;
}

@media (max-width: 1100px) {
  .custom-link {
    font-size: 18px;
    margin-right: 28px;
  }
}
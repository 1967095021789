@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Roboto+Condensed:ital@1&display=swap');

.navbar {
  background-color: transparent;
  backdrop-filter: blur(18px);
  position: sticky;
  top: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 40px;
  box-sizing: border-box;
  padding: 12px 28px 12px 16px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  z-index: 1;
}

.logoLink {
  font-family: 'Lobster', cursive;
  color: lightcoral;
  text-decoration: none;
  padding: 8px 8px 8px 8px;
  border-radius: 8px;
}

.logoLink:hover {
  background-color: gainsboro;
  color: darkred;
}
.outerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 140px;
  background-color: seashell;
  width: var(--width-desktop, 100%);
  height: var(--height-desktop, 100vh);
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  max-width: var(--max-text-width-desktop, 450px);
}

.textContainer>h1 {
  font-family: inherit;
  font-weight: 800;
  font-size: 36px;
  color: black;
  box-sizing: border-box;
  padding-bottom: 10px;
}

.textContainer>ul {
  list-style: disc;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.textContainer>ul>li {
  font-family: inherit;
  font-weight: 600;
  font-size: 18px;
  color: black;
  line-height: 30px;
  box-sizing: border-box;
}

.imageContainerBase {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.imgContainerMobile {
  display: none;
  width: 100%;

  /* Due to a bug with flexbox justify-content and overflowing content, we need to use "margin-bottom: auto" on the 
    bottom flex child instead of the "justify-content: center" on the parent. This bug can be fixed with 
    "justify-content: center safe" bug current browser support is poor, so it is not used.
    Note that this is only relevant in mobile view where the "justify-content" property is set back to "flex-start" */
  margin-bottom: auto;
}

.textContainer>ul a {
  text-decoration: none;
}

.textContainer>ul a:link {
  color: var(--link-normal-color);
}

.textContainer>ul a:visited {
  color: var(--link-visited-color);
}

.imageMargins {
  margin: 0 auto;
}

@media (max-width: 1100px) {
  .outerContainer {
    width: var(--width-mobile, 100%);
    height: var(--height-mobile, min-content);
    min-width: var(--width-mobile, 100%);
    padding: 40px 30px;
    flex-direction: column;
    gap: 50px;
    overflow-x: hidden;
    overflow-y: scroll;
    justify-content: flex-start;
  }

  .imgContainerDesktop {
    display: none;
  }

  .imgContainerMobile {
    display: flex;
  }

  .textContainer {
    max-width: none;

    /* Due to a bug with flexbox justify-content and overflowing content, we need to use "margin-top: auto" on the top
    flex child instead of the "justify-content: center" on the parent. This bug can be fixed with 
    "justify-content: center safe" bug current browser support is poor, so it is not used */
    margin-top: auto;
  }

  .textContainer>ul {
    gap: 10px;
  }

  .textContainer>h1 {
    font-size: 24px;
  }

  .textContainer>ul>li {
    font-size: 16px;
  }
}
.buttonContainerDesktop {
  display: flex;
  gap: 24px;
  flex-direction: row-reverse;
  padding-right: 10px;
}

.buttonContainerMobile {
  display: none;
  gap: 14px;
  flex-direction: row-reverse;
  padding-right: 28px;
}

.frame {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  box-shadow: 0px 3px 4px 3px #c4bcb7;
}

@media (max-width: 1100px) {
  .buttonContainerDesktop {
    display: none;
  }

  .buttonContainerMobile {
    display: flex;
  }

  .frame {
    border-radius: 0;
    box-shadow: none;
  }
}
.link {
  text-decoration: none;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
  font-size: 18px;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 28px 16px 28px;
  border: solid 2px;
  border-color: transparent transparent white transparent;
}

.spinner {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid;
  border-color: white transparent white transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.container {
  --delay: 1s;
  --duration: 6.4s;
}

.marker {
  stroke-dasharray: var(--markerLength);
  stroke-dashoffset: var(--markerLength);
  animation:
    drawMarker var(--duration) ease-in-out var(--delay) normal forwards,
    changeMarkerColor var(--duration) ease-in-out var(--delay) forwards;
}

.dot {
  stroke-dasharray: var(--dotLength);
  stroke-dashoffset: var(--dotLength);
  animation:
    drawDot var(--duration) ease-in-out var(--delay) normal forwards,
    changeMarkerColor var(--duration) ease-in-out var(--delay) forwards;
}

.circle {
  animation: changeCircleColor var(--duration) ease-in-out var(--delay) forwards;
}

@keyframes drawMarker {

  35%,
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes drawDot {
  35% {
    stroke-dashoffset: var(--dotLength);
  }

  60%,
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes changeMarkerColor {

  60%,
  100% {
    stroke: white;
    stroke-width: 5;
  }

  80% {
    stroke: black;
    stroke-width: 12;
  }
}

@keyframes changeCircleColor {

  60%,
  100% {
    fill: black;
  }

  80% {
    fill: white;
  }
}
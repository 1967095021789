.base {
  position: fixed;
  top: 50%;
  z-index: 2;
}

.incomingCardL {
  left: 0%;
  transform: translate(-100%, calc(-50% + var(--displayContainerVerticalOffset)));
}

.incomingCardR {
  right: 0%;
  transform: translate(100%, calc(-50% + var(--displayContainerVerticalOffset)));
}

.outgoingCard {
  left: 50%;
  transform: translate(-50%, calc(-50% + var(--displayContainerVerticalOffset)));
}

.incomingSlideR {
  animation: incomingSlideRight var(--animationDuration, 0.5s) ease-in-out;
}

.incomingSlideL {
  animation: incomingSlideLeft var(--animationDuration, 0.5s) ease-in-out;
}

.outgoingSlideR {
  animation: outgoingSlideRight var(--animationDuration, 0.5s) ease-in-out;
}

.outgoingSlideL {
  animation: outgoingSlideLeft var(--animationDuration, 0.5s) ease-in-out;
}

@keyframes incomingSlideRight {
  100% {
    left: 50%;
    transform: translate(-50%, calc(-50% + var(--displayContainerVerticalOffset)));
  }
}

@keyframes incomingSlideLeft {
  100% {
    right: 50%;
    transform: translate(50%, calc(-50% + var(--displayContainerVerticalOffset)));
  }
}

@keyframes outgoingSlideRight {
  100% {
    left: 100%;
    transform: translate(100%, calc(-50% + var(--displayContainerVerticalOffset)));
  }
}

@keyframes outgoingSlideLeft {
  100% {
    left: 0%;
    transform: translate(-100%, calc(-50% + var(--displayContainerVerticalOffset)));
  }
}
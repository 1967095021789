.outerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.closeButtonContainer {
  display: flex;
  flex-direction: row-reverse;
  width: var(--cardWidth);
}

.displayContainer {
  display: flex;
  align-items: center;
  gap: 30px;
}

@media (max-width: 1100px) {
  .overallContainer {
    display: none;
  }
}
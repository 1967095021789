.target {
  padding: 3px;
  border: red solid 4px;
  border-radius: 10px;
}

@media (max-width: 1100px) {
  .desktop {
    display: none;
  }

  .target {
    animation: flash 3s linear infinite;
  }
}

@keyframes flash {
  0% {
    border-color: red;
  }

  50% {
    border-color: white;
  }

  100% {
    border-color: red;
  }
}

@media (min-width: 1101px) {
  .mobile {
    display: none;
  }
}
.logoContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  max-width: 460px;
  justify-content: center;
}

.logoContainer img {
  object-fit: contain;
}

@media (max-width: 1100px) {
  .logoContainer {
    padding: 0 20px;
  }
}
.title {
  border-radius: 24px 24px 0px 0px;
  background-color: black;
  width: 26%;
  margin-left: 4%;
  display: flex;
  align-items: center;
  padding: 16px 0 16px 24px;
}

.title>p {
  display: inline-block;
  color: white;
  font-weight: 600;
}
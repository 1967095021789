.page-background {
  background-color: seashell;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.content-block {
  flex-grow: 1;
  max-width: var(--maxWidthDesktop, 1000px);
  gap: var(--gapDesktop, 0);
  box-sizing: border-box;
}

@media (max-width: 1100px) {
  .content-block {
    max-width: 100vw;
    gap: var(--gapMobile, 0);
  }
}
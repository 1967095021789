.auth-help {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  margin-bottom: 16px;
}

.auth-help-link {
  color: blue;
  font-weight: 600;
}

.auth-help-link:visited {
  color: purple
}
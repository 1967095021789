.auth-container {
  background-color: #E5E5E5;
  border-radius: 45px;
  width: 82%;
  max-width: 430px;
  height: 467px;
  /* Below is needed for the auth-container-title margins to work properly. If overflow: auto is not added 
  or auth-container does not have any padding, then the margins of auth-container-title will not work */
  overflow: auto;
}

.auth-container-title {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 18px;
  font-weight: 600;
  margin-top: 30px;
  margin-left: 39px;
}

.auth-container>.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

@media (max-width: 1100px) {
  .auth-container-title {
    margin-left: 24px;
  }
}
.input-text-label {
  display: flex;
  flex-direction: column;
  width: 78%;
  max-width: 300px;
  ;
}

.input-text-label>.label {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  margin-left: 8px;
  margin-bottom: 4px;
}

.input-text-label>.label-error {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  margin-left: 8px;
  margin-bottom: 4px;
  margin-top: 3px;
  color: red;
}

.input-text-box {
  height: 44px;
  font-size: 16px;
  border-radius: 10px;
  border-style: none;
}

.input-text-box-error {
  height: 44px;
  font-size: 16px;
  border-radius: 10px;
  border-style: solid;
  border-color: red;
}
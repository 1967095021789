* {
  box-sizing: border-box;
}

.savePane,
.topPaneDesktop,
.botPane {
  width: 80vw;
}

.savePane {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  margin-top: 56px;
}

.topPaneDesktop {
  height: 38vh;
  display: flex;
  justify-content: space-between;
  gap: 2%;
}

.topPaneMobile {
  display: none;
  justify-content: center;
  width: 100%;
}

.botPane {
  height: 38vh;
  padding: 10px;
  background-color: gainsboro;
  border-radius: 20px;
}

.output {
  width: 100%;
  height: 100%;
}

@media (max-width: 1100px) {

  .savePane,
  .botPane {
    width: 100%;
  }

  .topPaneDesktop {
    display: none;
  }

  .topPaneMobile {
    display: flex;
  }

  .botPane {
    height: 100%;
    border-radius: 0;
  }

  .savePane {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-right: 20px;
  }
}
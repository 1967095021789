.bin {
  width: 100%;
  height: 100%;
}

.lid {
  animation: openCloseLid 0.6s linear 0.1s 2 alternate;
}

.rubbish {
  transform: translate(0%, -40%);
  animation: throwRubbish 0.6s ease-in 0.1s forwards;
}

@keyframes openCloseLid {
  50% {
    transform: translate(-34%, 38%) rotate(-45deg);
  }

  100% {
    transform: translate(-14%, 105%) rotate(-90deg);
  }
}

@keyframes throwRubbish {
  100% {
    transform: translate(0%, 62%);
  }
}
.loadingCard {
  display: flex;
  justify-content: center;
}

.blogsContainer {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  flex-grow: 1;
}

.mobileCreateBtn {
  display: none;
  margin-right: 28px;
}

@media (max-width: 1100px) {
  .blogsContainer {
    gap: 12px;
  }

  .desktopCreateBtn {
    display: none;
  }

  .mobileCreateBtn {
    display: block;
  }
}
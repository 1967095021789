.checkbox {
  max-width: 45%;
  max-height: 80%;
}

.check {
  stroke-dasharray: 93.0677261352539;
  stroke-dashoffset: 93.0677261352539;
  animation: drawTick 0.8s ease-in-out 0.5s forwards;
}

.circle {
  stroke-dasharray: 302;
  stroke-dashoffset: 302;
  animation: drawTick 0.8s ease-in-out forwards;
}

@keyframes drawTick {
  100% {
    stroke-dashoffset: 0;
  }
}
.desktopNav {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.nav {
  display: flex;
  gap: 8px;
}

.buttonNav {
  display: flex;
  align-items: center;
  list-style-type: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
}

.navLink {
  color: black;
  text-decoration: none;
  padding: 8px 8px 8px 8px;
}

.navLink:hover {
  background-color: gainsboro;
  border-radius: 8px;
}

.auth {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.buttonAuth {
  list-style-type: none;
  border-radius: 8px;
}

.authLink {
  text-decoration: none;
}

@media (max-width: 1100px) {
  .desktopNav {
    display: none;
  }
}
.tag {
  background-color: #e5e5e5;
  border-radius: 12px;
  padding: 6px 20px 6px 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
  font-style: italic;
  height: max-content;
}

.link {
  text-decoration: none;
}

.link:link,
.link:visited,
.link:hover,
.link:active {
  color: inherit;
}

.outerContainer {
  display: flex;
  gap: 24px;
  align-items: center;
  box-shadow: 0px 3px 4px 3px #c4bcb7;
  padding: 20px 18px 20px 18px;
  width: 100%;
  height: 200px;
  transition: transform 0.3s ease-in-out;
  box-sizing: border-box;
  background-color: white;
}

.outerContainer:hover {
  box-shadow: 0px 3px 16px 4px #c4bcb7;
  cursor: pointer;
  transform: translateY(-10px);
}

.thumbnail {
  object-fit: cover;
  --img-width: 160px;
  --img-height: 94%;
  min-width: var(--img-width);
  min-height: var(--img-height);
  max-width: var(--img-width);
  max-height: var(--img-height);
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  overflow: hidden;
}

.title {
  color: darkred;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 20px;
  font-weight: 800;
  margin: 0px 0px 0px 0px;
}

.tagContainer {
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
  flex-wrap: nowrap;
}

.title,
.tagContainer {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  box-sizing: border-box;
}

.summary {
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  line-height: 24px;
  margin: 0px 0px 0px 0px;
  max-height: 40%;
  min-height: 30%;
  overflow: auto;
}

@media (max-width: 1100px) {
  .summary {
    display: none;
  }

  .title {
    font-size: 16px;
    max-height: 30%;
    padding: 2px 0;
  }

  .tag {
    padding: 6px 14px 6px 14px;
    font-size: 14px;
  }

  .tagContainer {
    gap: 8px;
    max-height: 60%;
  }

  .title,
  .tagContainer {
    overflow: auto;
    white-space: normal;
    flex-wrap: wrap;
  }
}

@media (min-width: 1100px) {

  .tagContainer,
  .title {
    padding-bottom: 6px;
  }

  .tagContainer::-webkit-scrollbar,
  .title::-webkit-scrollbar {
    height: 4px;
  }

  .tagContainer::-webkit-scrollbar-thumb,
  .title::-webkit-scrollbar-thumb {
    background-color: #c0c0c0;
    border-radius: 2px;
  }

  .summary::-webkit-scrollbar {
    width: 10px;
  }

  .summary::-webkit-scrollbar-thumb {
    background-color: #c0c0c0;
    border-radius: 4px;
  }

  .summary {
    padding-right: 2px;
  }
}
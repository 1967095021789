.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message {
  margin-bottom: 40px;
}

@media (max-width: 1100px) {
  .code {
    font-size: 2.2rem;
    margin-bottom: 18px;
  }

  .message {
    font-size: 1.1rem;
    margin-bottom: 18px;
  }
}